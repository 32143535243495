// locale site redirect
var supportedLanguages = ["it", "en-us", "en-gb"];
var browserLang = navigator.language || navigator.systemLanguage;
var lang = browserLang.toLowerCase();
var found = supportedLanguages.indexOf(lang);
var baseUrl = window.location.protocol + "//" + window.location.host;
var forcedChangeLanguage = location.search.split('lang=')[1];
if (!forcedChangeLanguage) {
    if (found == 1 && window.location.href.indexOf("us") == -1) {
        dest = baseUrl + "/index-us.html";
        window.location.replace ? window.location.replace(dest) : window.location = dest;
    } else if (found == 0 && window.location.href.indexOf("it") == -1) {
        dest = baseUrl + "/index-it.html";
        window.location.replace ? window.location.replace(dest) : window.location = dest;
    }
}

// anchor redirects
// used for SEO redirects to the new pricing page location
function locationHashChanged() {
    if (location.hash === "#pricing") {
        window.location.replace("pricing.html");
    }
}

window.onhashchange = locationHashChanged;
