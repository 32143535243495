$("#contactForm").submit(function (e) {
    var email = $("#field_email").val();
    var message = $("#field_message").val();
    if (email.length < 3 || message.length < 2) {
        alert("Fill all the fields");
    } else {
        e.preventDefault();
        var arr = {
            senderEmail: email,
            message: message + "\n\n\n\n----------------------------------------------\nMessage sent from the frontend contact form.\n" + getBrowserOsInfo()
        };
        $.ajax({
            url: 'api/contact',
            type: 'POST',
            data: JSON.stringify(arr),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            async: true,
            success: function () {
                $(".successMessages").show();
                $(".errorMessages").hide();
                $("#new_message").show();
                $("#contactForm").hide();
                $("#field_email").val("");
                $("#field_message").val("");
            },
            error: function () {
                $(".errorMessages").show().text("Sorry an error happened and we couldn't submit your request. Please try again");
                $(".successMessages").hide();
                $("#contactForm").show();
                $("#new_message").hide();
            }
        });
    }
});

$("#newMessageButton").click(function () {
    $("#contactForm").show();
    $(".successMessages").hide();
    $(".errorMessages").hide();
    $("#new_message").hide();
});

function getBrowserOsInfo() {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) != -1) {
            fullVersion = nAgt.substring(verOffset + 8);
        }
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) != -1) {
            fullVersion = nAgt.substring(verOffset + 8);
        }
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1) {
        fullVersion = fullVersion.substring(0, ix);
    }
    if ((ix = fullVersion.indexOf(" ")) != -1) {
        fullVersion = fullVersion.substring(0, ix);
    }

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    return ''
        + 'Browser name  = ' + browserName + '\n'
        + 'Full version  = ' + fullVersion + '\n'
        + 'Major version = ' + majorVersion + '\n'
        + 'navigator.appName = ' + navigator.appName + '\n'
        + 'navigator.userAgent = ' + navigator.userAgent + '\n';
}
