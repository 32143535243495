/*
Theme: Flatfy Theme
Author: Andrea Galanti
Bootstrap Version
Build: 1.0
http://www.andreagalanti.it
*/

$(window).load(function() {
	//Preloader
	$('#status').delay(300).fadeOut();
	$('#preloader').delay(300).fadeOut('slow');
	$('body').delay(550).css({'overflow':'visible'});
})

$(document).ready(function() {

});

